
import { Helmet } from 'react-helmet';

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Funifier | Demo Projects</title>
        <link rel="canonical" href="https://demo.funifier.com" />
      </Helmet>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>Project Demos</h2>
        </header>
        <div className="menu">
          <ul>
            <li>
              <a href="/team-race" target="_blank">
                F1 Racing (Teams)
              </a>
            </li>
            <li>
              <a href="/regattas" target="_blank">
                Regattas (Individual/Teams)
              </a>
            </li>
            <li>
              <a href="/widgets?player=demo@funifier.com&player_name=Demo" target="_blank">
                Widgets
              </a>
            </li>
            <li>
              <a href="/virtual-events" target="_blank">
                Virtual Events
              </a>
            </li>
            <li>
              <a href="/metaverse" target="_blank">
                Metaverse
              </a>
            </li>
            <li>
              <a href="/checkin" target="_blank">
                Events Check-in
              </a>
            </li>
            <li className='disabled'>
              <a href="/sales-dashboard" target="_blank">
                World Builder (Sales)
              </a>
            </li>
            <li className='disabled'>
              <a href="/sales-teams" target="_blank">
                Colony on Mars (Sales/Teams)
              </a>
            </li>
            <li className='disabled'>
              <a href="/campus" target="_blank">
                Campus (Schools/HR Onboarding)
              </a>
            </li>
            <li className='disabled'>
              <a href="/education" target="_blank">
                Galaxy Exploration (Education)
              </a>
            </li>
            <li className='disabled'>
              <a href="/fitness" target="_blank">
                Fitness
              </a>
            </li>
            <li className='disabled'>
              <a href="/serious-game" target="_blank">
                Serious Game
              </a>
            </li>
            <li className='disabled'>
              <a href="/elections" target="_blank">
                Government/Elections
              </a>
            </li>
          </ul>
        </div>
        <span><small>v0.0.1</small></span>
      </div>
    </>
  );
}

export default App;
